import React from "react";
import { Link } from "react-router-dom";
import "./Goodbye.css"; // CSSファイルをインポート

const Goodbye = () => {
  return (
    <div className="goodbye-container">
      <h1 className="goodbye-title">退会が完了しました</h1>
      <p className="goodbye-message">
        ご利用ありがとうございました。またのご利用をお待ちしております。
      </p>
      <Link to="/" className="home-link">
        トップページへ戻る
      </Link>
    </div>
  );
};

export default Goodbye;
