import React from "react";
import "./PrivacyPolicy.css";

const TermsOfService = () => {
  return (
    <div className="terms-of-service privacypolicy">
      <h1>利用規約</h1>
      <h2>第1条（適用範囲）</h2>
      <p>
        本規約は、TrendTagger（以下「当サイト」といいます）が提供する全てのサービス（以下「本サービス」といいます）に適用されます。ユーザーが本サービスを利用する際は、本規約を遵守するものとします。
      </p>

      <h2>第2条（サービス内容）</h2>
      <p>
        当サイトは、Instagramから取得した情報をもとに以下の機能を提供します：
      </p>
      <ul>
        <li>特定のハッシュタグごとに「いいね数」の多い投稿ランキングの表示</li>
        <li>
          特定のハッシュタグに対する投稿数およびいいね数の推移を示す折れ線グラフの表示
        </li>
        <li>特定のハッシュタグと併用される他のハッシュタグを円グラフで表示</li>
      </ul>
      <p>
        あらかじめ指定されたハッシュタグに関する情報は、全てのユーザーが無料で閲覧可能です。検索機能を使用して指定以外のハッシュタグ情報を閲覧するには、ログインが必要です。なお、本サービスは有料プランを提供しておらず、ログイン後も無料で利用することができます。
      </p>

      <h2>第3条（Instagram APIの利用について）</h2>
      <p>
        当サイトでは、Instagram
        APIを利用して公開されている投稿データを取得し、サイト内での分析や表示を行っています。APIで取得するデータには個人情報は含まれておらず、Instagramが提供する利用規約に従い適切に管理・利用されます。
      </p>

      <h2>第4条（アカウントの登録と管理）</h2>
      <p>
        1.
        ユーザーは、本サービスを利用するためにアカウントを作成する必要があります。登録には、適切な情報を提供し、正確なデータを維持する責任を負います。
      </p>
      <p>
        2.
        ユーザーは、自らのアカウントのパスワードおよびその他の認証情報を安全に管理し、第三者に不正利用されないよう努める義務があります。
      </p>
      <p>
        3.
        ユーザーは、アカウントの不正利用が判明した場合、速やかに当サイトに通知するものとします。
      </p>

      <h2>第5条（広告の表示）</h2>
      <p>
        当サイトは、サービスの維持および運営のために広告を表示することがあります。ユーザーは、広告の内容について当サイトが責任を負わないことを理解し、承諾するものとします。
      </p>

      <h2>第6条（禁止事項）</h2>
      <p>
        ユーザーは、本サービスの利用に際し、以下の行為を行ってはなりません。
      </p>
      <ul>
        <li>法令または公序良俗に違反する行為</li>
        <li>サーバーやネットワークに過度の負担をかける行為</li>
        <li>本サービスに関連する情報を不正に取得・使用する行為</li>
        <li>当サイトの運営や他のユーザーの利用を妨害する行為</li>
      </ul>

      <h2>第7条（サービスの停止・変更）</h2>
      <p>
        当サイトは、運営上の理由やシステムメンテナンス、法令順守のために、予告なく本サービスの一部または全部を停止、変更することがあります。
      </p>

      <h2>第8条（免責事項）</h2>
      <p>
        1.
        当サイトは、本サービスの提供において、正確かつ最新の情報を提供するために努めますが、提供情報の正確性、完全性、適時性について保証するものではありません。
      </p>
      <p>
        2.
        当サイトは、本サービスの利用に起因するユーザーの損害について、一切の責任を負いません。
      </p>

      <h2>第9条（利用規約の変更）</h2>
      <p>
        当サイトは、必要に応じて本規約を変更することができるものとします。変更後の規約は、当サイト上に掲載された時点から効力を生じるものとし、ユーザーは変更後も引き続きサービスを利用する場合は、その内容に同意したものとみなされます。
      </p>

      <h2>第10条（準拠法・裁判管轄）</h2>
      <p>
        本規約の準拠法は日本法とし、本サービスに関して生じた紛争については、当サイトの所在地を管轄する裁判所を第一審の専属的合意管轄とします。
      </p>

      <p>お問い合わせ先: support@trendtagger.com</p>
    </div>
  );
};

export default TermsOfService;
