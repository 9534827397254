import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { ja } from "date-fns/locale";
import { Chart, registerables } from "chart.js";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../config";
import SearchBar from "../SearchBar/SearchBar";
import { HashtagContext } from "../../context/HashtagContext";
import "./AnalyticsChart.css";
import {
  format,
  startOfWeek,
  endOfWeek,
  parseISO,
  isWithinInterval,
  eachDayOfInterval,
  subWeeks,
  differenceInDays,
} from "date-fns";
import AdCard from "../AdCard/AdCard";
import OverlayAd from "../AdCard/OverlayAd";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";
import { getCookie } from "../../utils/cookies";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

Chart.register(...registerables);

const WeeklyAnalyticsChart = () => {
  const [key, setKey] = useState(0);
  const [chartData, setChartData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [showOverlayAd, setShowOverlayAd] = useState(false);
  const [startDate, setStartDate] = useState(
    startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 })
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 })
  );
  const [error, setError] = useState("");
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { currentHashtags, handleAnalyticsSearch } = useContext(HashtagContext);

  const MAX_RANGE_DAYS = 14;
  const SEARCH_THRESHOLD = 3;

  useEffect(() => {
    if (location.state) {
      setSearchTerm(location.state.searchTerm || "");
      setChartData(location.state.chartData || {});
      setStartDate(location.state.startDate || startDate);
      setEndDate(location.state.endDate || endDate);
      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
      }, 0);
    }
  }, [location.state, startDate, endDate]);

  useEffect(() => {
    if (differenceInDays(endDate, startDate) > MAX_RANGE_DAYS) {
      setError("日付範囲は最大2週間までです。");
      return;
    } else if (startDate > endDate) {
      setError("開始日が終了日より後の日付です。");
      return;
    } else {
      setError("");
    }

    const fetchHashtagData = async () => {
      try {
        setLoading(true);
        setAuthError(false);
        let fetchCount = 0;

        // CSRFトークンを取得
        await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
          withCredentials: true,
        });

        const csrfToken = getCookie("XSRF-TOKEN");

        const dateLabels = eachDayOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => format(date, "yyyy-MM-dd"));

        // ハッシュタグごとにリクエストを送信
        currentHashtags.forEach(async (hashtag) => {
          const isDefaultHashtag = config.defaultHashtags.includes(hashtag);
          const url = isDefaultHashtag
            ? `${config.apiBaseUrl}/api/weekly-analytics/${encodeURIComponent(
                hashtag
              )}?start_date=${format(
                startDate,
                "yyyy-MM-dd"
              )}&end_date=${format(endDate, "yyyy-MM-dd")}`
            : `${
                config.apiBaseUrl
              }/api/weekly-analytics/secure/${encodeURIComponent(
                hashtag
              )}?start_date=${format(
                startDate,
                "yyyy-MM-dd"
              )}&end_date=${format(endDate, "yyyy-MM-dd")}`;

          try {
            const response = await axios.get(url, {
              withCredentials: true,
              headers: {
                "X-XSRF-TOKEN": csrfToken,
                Accept: "application/json",
              },
            });


            const filteredData = response.data.filter((item) => {
              const itemDate = parseISO(item.date);
              return isWithinInterval(itemDate, {
                start: startDate,
                end: endDate,
              });
            });

            const likeCounts = dateLabels.map((label) => {
              const dataItem = filteredData.find(
                (item) => format(parseISO(item.date), "yyyy-MM-dd") === label
              );
              return dataItem ? dataItem.like_count : 0;
            });

            const postCounts = dateLabels.map((label) => {
              const dataItem = filteredData.find(
                (item) => format(parseISO(item.date), "yyyy-MM-dd") === label
              );
              return dataItem ? dataItem.post_count : 0;
            });

            setChartData((prevData) => ({
              ...prevData,
              [hashtag]: {
                labels: dateLabels,
                datasets: [
                  {
                    label: `いいね数`,
                    data: likeCounts,
                    borderColor: "rgba(75, 192, 192, 1)",
                    fill: false,
                    yAxisID: "y1",
                    pointRadius: 6,
                    pointHoverRadius: 8,
                    pointStyle: "circle",
                  },
                  {
                    label: `投稿数`,
                    data: postCounts,
                    borderColor: "rgba(255, 99, 132, 1)",
                    fill: false,
                    yAxisID: "y2",
                    pointRadius: 6,
                    pointHoverRadius: 8,
                    pointStyle: "circle",
                  },
                ],
              },
            }));
          } catch (error) {
            if (error.response && error.response.status === 401) {
              setAuthError(true);
            }
          } finally {
            fetchCount += 1;
            if (fetchCount === currentHashtags.length) {
              setLoading(false);
            }
          }
        });
      } catch (error) {
        setLoading(false);
      }
    };

    fetchHashtagData();
  }, [currentHashtags, startDate, endDate, key, location.state]);

  const handlePointClick = (elements, hashtag) => {
    if (elements.length > 0) {
      const date = chartData[hashtag].labels[elements[0].index];
      navigate(`/hourly/${hashtag}/${date}`, {
        state: {
          hashtag,
          date,
          searchTerm,
          chartData,
          startDate,
          endDate,
          retainSearch: true,
        },
      });
    }
  };

  const handleSearchSubmit = (searchTerm) => {
    handleAnalyticsSearch(searchTerm);

    const searchCount = parseInt(
      localStorage.getItem("searchCount") || "0",
      10
    );
    localStorage.setItem("searchCount", searchCount + 1);

    if ((searchCount + 1) % SEARCH_THRESHOLD === 0) {
      setShowOverlayAd(true);
    }
  };

  const getRandomOverlayAd = () => {
    const randomIndex = Math.floor(
      Math.random() * config.overlayAdDetails.length
    );
    return config.overlayAdDetails[randomIndex];
  };

  const getRandomAd = () => {
    const randomIndex = Math.floor(
      Math.random() * config.adDetailsForOtherPages.length
    );
    return config.adDetailsForOtherPages[randomIndex];
  };

  return (
    <div className="analytics-charts weekly-analytics-charts">
      <SearchBar
        label="日付ごとのいいね数を検索"
        placeholder="ハッシュタグを入力"
        onSubmit={handleSearchSubmit}
      />
      <div className="date-picker-container">
        <label>開始日: </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="yyyy/MM/dd"
        />
        <label>終了日: </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="yyyy/MM/dd"
        />
      </div>
      {error && <div className="error-message">{error}</div>}
      {loading ? (
        <LoadingIndicator />
      ) : authError ? (
        <NoResultsMessage
          message={`ログインが必要です。ログインまたは新規登録をしてください。<br />ログイン後、全てのハッシュタグ検索が無料で可能となります。`}
        />
      ) : (
        currentHashtags.map((hashtag, index) => (
          <React.Fragment key={hashtag}>
            <div className="chart-container">
              <h2>ハッシュタグ： {hashtag}</h2>
              <div className="chart-wrapper">
                {chartData[hashtag] && chartData[hashtag].labels.length > 0 ? (
                  <Line
                    data={chartData[hashtag]}
                    options={{
                      onClick: (e, elements) =>
                        handlePointClick(elements, hashtag),
                      scales: {
                        x: {
                          type: "time",
                          time: {
                            unit: "day",
                            tooltipFormat: "MMM d, yyyy",
                            displayFormats: {
                              day: "MMM d (EEE)",
                            },
                            adapters: {
                              date: {
                                locale: ja,
                              },
                            },
                          },
                          title: {
                            display: true,
                            text: "Date",
                          },
                          ticks: {
                            callback: function (value, index, values) {
                              const date = new Date(value);
                              const formattedDate = format(date, "MM/dd (E)", {
                                locale: ja,
                              });
                              return formattedDate;
                            },
                          },
                        },
                        y1: {
                          type: "linear",
                          position: "left",
                          title: {
                            display: true,
                            text: "いいね数",
                          },
                          ticks: {
                            color: "rgba(75, 192, 192, 1)",
                          },
                        },
                        y2: {
                          type: "linear",
                          position: "right",
                          title: {
                            display: true,
                            text: "投稿数",
                          },
                          grid: {
                            drawOnChartArea: false,
                          },
                          ticks: {
                            color: "rgba(255, 99, 132, 1)",
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: "top",
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                    style={{ height: "100%", width: "100%" }}
                  />
                ) : (
                  <NoResultsMessage />
                )}
              </div>
              <p className="data-explanation">
                ＊特定の日付データをクリックすると、時間ごとの分析が表示されます
              </p>
            </div>
            {(index + 1) % 2 === 0 && <AdCard adHtml={getRandomAd().adHtml} />}
          </React.Fragment>
        ))
      )}
      {authError && (
        <div className="auth-prompt">
          <button className="auth-button" onClick={() => navigate("/login")}>
            ログイン
          </button>
          <button className="auth-button" onClick={() => navigate("/register")}>
            新規登録
          </button>
        </div>
      )}
      {showOverlayAd && (
        <OverlayAd
          adHtml={getRandomOverlayAd().adHtml}
          onClose={() => setShowOverlayAd(false)}
        />
      )}
    </div>
  );
};

export default WeeklyAnalyticsChart;
