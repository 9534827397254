import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../../utils/cookies"; // CSRFトークン取得の関数
import config from "../../config"; // ベースURL設定
import "./GoogleCallback.css";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchGoogleCallback = async () => {
      try {
        // クエリパラメータを取得
        const params = new URLSearchParams(location.search);

        // CSRFトークンを取得
        const csrfToken = getCookie("XSRF-TOKEN");

        // GoogleコールバックURLにリクエストを送信
        const url = `${
          config.apiBaseUrl
        }/api/login/google/callback?${params.toString()}`;
        const response = await axios.get(url, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken,
          },
        });

        // ログイン成功時の処理
        const { username } = response.data.user;

        // // トークンやユーザー情報を保存（ここでは例としてlocalStorageを使用）
        // localStorage.setItem("token", data.token);
        // localStorage.setItem("user", JSON.stringify(data.user));

        if (username) {
          localStorage.setItem("user", JSON.stringify({ username }));

          // カスタムイベントを発火してHeaderに伝える
          const event = new CustomEvent("userRegistered", {
            detail: { username },
          });
          window.dispatchEvent(event);

          // ホームページにリダイレクト
          navigate("/");
        } else {
          return;
        }
      } catch (error) {
        alert("ログインに失敗しました。再試行してください。");
      }
    };

    fetchGoogleCallback();
  }, [location, navigate]);

  return (
    <div className="callback-card">
      <h1>認証中...</h1>
      <div className="spinner"></div>
    </div>
  );
};

export default GoogleCallback;
