const config = {
  // apiBaseUrl: "http://192.168.20.10:8000/api", // ローカルLinuxPCとの通信用
  apiBaseUrl: "https://api.trend-tagger.com",
  defaultHashtags: [
    "カフェ",
    "ファッション",
    "旅行",
    "美容",
    "ペット",
  ],

  // 人気投稿ページ
  adDetailsForPostsPages: [
    {
      adHtml: `
        <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+6O1Z5" rel="nofollow">
          <img border="0" width="300" height="250" alt="" src="https://www20.a8.net/svt/bgt?aid=231018440875&wid=001&eno=01&mid=s00000008903001095000&mc=1">
          <div class="post-info">
            <p class="post-caption">【PR】初心者も安心のサポート充実【DMM FX】</p>
          </div>
        </a>
        <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+6O1Z5" alt="">
      `,
    },
    {
      // エックスサーバー
      adHtml: `
        <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+614CY" rel="nofollow">
          <img border="0" width="300" height="250" alt="" src="https://www28.a8.net/svt/bgt?aid=231020497984&wid=001&eno=01&mid=s00000001642001120000&mc=1">
          <div class="post-info">
            <p class="post-caption">【PR】独自ドメインを複数お持ちの方にお勧めのレンタルサーバー！</p>
          </div>
        </a>
        <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+614CY" alt="">
      `,
    },
  ],

  // 人気投稿ページのスマホサイズ
  adDetailsForSmallScreens: [
    {
      adHtml: `
      <a href="https://px.a8.net/svt/ejp?a8mat=3TJIYW+EGYB3M+1WP2+6GC75" rel="nofollow">
      <img border="0" width="250" height="300" alt="" src="https://www28.a8.net/svt/bgt?aid=231018440875&wid=001&eno=01&mid=s00000008903001084000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3TJIYW+EGYB3M+1WP2+6GC75" alt="">
      `,
    },
    {
      adHtml: `
      <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+674EP" rel="nofollow">
      <img border="0" width="200" height="200" alt="" src="https://www25.a8.net/svt/bgt?aid=231020497984&wid=001&eno=01&mid=s00000001642001041000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+674EP" alt="">
      `,
    },
    // 他の広告を追加
  ],

  // 人気投稿ページ以外のページ
  adDetailsForOtherPages: [
    {
      adHtml: `
        <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+6L1Y9" rel="nofollow">
        <img border="0" width="468" height="60" alt="" src="https://www21.a8.net/svt/bgt?aid=231020497984&wid=001&eno=01&mid=s00000001642001106000&mc=1"></a>
        <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+6L1Y9" alt="">
      `,
    },
    {
      adHtml: `
        <a href="https://px.a8.net/svt/ejp?a8mat=3TJIYW+EGYB3M+1WP2+64Z8X" rel="nofollow">
        <img border="0" width="468" height="60" alt="" src="https://www23.a8.net/svt/bgt?aid=231018440875&wid=001&eno=01&mid=s00000008903001031000&mc=1"></a>
        <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3TJIYW+EGYB3M+1WP2+64Z8X" alt="">
      `,
    },
    // 他の広告を追加
  ],

  // 検索時のオーバーレイ広告
  overlayAdDetails: [
    {
      adHtml: `
        <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+6CWQP" rel="nofollow">
        <img border="0" width="336" height="280" alt="" src="https://www26.a8.net/svt/bgt?aid=231020497984&wid=001&eno=01&mid=s00000001642001068000&mc=1"></a>
        <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+6CWQP" alt="">
      `,
    },
    {
      adHtml: `
      <a href="https://px.a8.net/svt/ejp?a8mat=3TJKK1+G9UK1E+CO4+6O1Z5" rel="nofollow">
      <img border="0" width="300" height="250" alt="" src="https://www22.a8.net/svt/bgt?aid=231020497984&wid=001&eno=01&mid=s00000001642001120000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3TJKK1+G9UK1E+CO4+6O1Z5" alt="">
      `,
    },
    {
      adHtml: `
      <a href="https://px.a8.net/svt/ejp?a8mat=3TJIYW+EGYB3M+1WP2+6IP2P" rel="nofollow">
      <img border="0" width="300" height="250" alt="" src="https://www26.a8.net/svt/bgt?aid=231018440875&wid=001&eno=01&mid=s00000008903001095000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3TJIYW+EGYB3M+1WP2+6IP2P" alt="">
      `,
    },
    {
      adHtml: `
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z973D+9Q2WHE+5JQ0+5YZ75" rel="nofollow">
      <img border="0" width="300" height="250" alt="" src="https://www23.a8.net/svt/bgt?aid=240614185588&wid=001&eno=01&mid=s00000025884001003000&mc=1"></a>
      <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z973D+9Q2WHE+5JQ0+5YZ75" alt="">
      `,
    },
    // 他の広告を追加
  ],
};

export default config;
