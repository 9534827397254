import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import "./PopularPosts.css";
import { FaHeart } from "react-icons/fa";
import instagramIcon from "../../assets/instagram-icon.png";
import SearchBar from "../SearchBar/SearchBar";
import { HashtagContext } from "../../context/HashtagContext";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import AdCard from "../AdCard/AdCard";
import OverlayAd from "../AdCard/OverlayAd";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";
import { getCookie } from "../../utils/cookies";

const PopularPosts = () => {
  const { currentHashtags, handleTopPostsSearch } = useContext(HashtagContext);
  const [posts, setPosts] = useState({});
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false); // 401エラーの状態を追加
  const [showOverlayAd, setShowOverlayAd] = useState(false);
  const navigate = useNavigate(); // useNavigateを使用してページ遷移を行う

  const SEARCH_THRESHOLD = 3;

  useEffect(() => {
    const fetchPostsWithCsrf = async () => {
      try {
        setLoading(true);
        setAuthError(false); // 初期状態ではエラーをクリア
        let fetchCount = 0;

        // CSRFトークンを取得
        await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
          withCredentials: true,
        });

        const csrfToken = getCookie("XSRF-TOKEN");

        // ハッシュタグごとにリクエストを送信
        currentHashtags.forEach(async (hashtag) => {
          const isDefaultHashtag = config.defaultHashtags.includes(hashtag);
          const url = isDefaultHashtag
            ? `${config.apiBaseUrl}/api/top-posts/${encodeURIComponent(
                hashtag
              )}`
            : `${config.apiBaseUrl}/api/top-posts/secure/${encodeURIComponent(
                hashtag
              )}`;

          try {
            const response = await axios.get(url, {
              withCredentials: true,
              headers: {
                "X-XSRF-TOKEN": csrfToken,
                Accept: "application/json",
              },
            });


            if (!Array.isArray(response.data)) {
              throw new Error(`Expected array but got ${typeof response.data}`);
            }

            setPosts((prevPosts) => ({
              ...prevPosts,
              [hashtag]: response.data,
            }));
          } catch (error) {
            if (error.response && error.response.status === 401) {
              setAuthError(true); // 401エラーが発生した場合、authErrorをtrueに設定
            }
          } finally {
            fetchCount += 1;
            if (fetchCount === currentHashtags.length) {
              setLoading(false);
            }
          }
        });
      } catch (error) {
        setLoading(false);
      }
    };

    fetchPostsWithCsrf();
  }, [currentHashtags]);

  const handleImageError = (event) => {
    event.target.src = instagramIcon;
  };

  const handleSearchSubmit = (searchTerm) => {
    handleTopPostsSearch(searchTerm);

    const searchCount = parseInt(
      localStorage.getItem("searchCount") || "0",
      10
    );
    localStorage.setItem("searchCount", searchCount + 1);

    if ((searchCount + 1) % SEARCH_THRESHOLD === 0) {
      setShowOverlayAd(true);
    }
  };

  const insertAdsRandomly = (postsArray, adDetails) => {
    if (postsArray.length < 2) return postsArray;

    const adIndexes = new Set();
    while (adIndexes.size < 2) {
      const randomIndex = Math.floor(Math.random() * postsArray.length);
      adIndexes.add(randomIndex);
    }

    const postsWithAds = [...postsArray];
    [...adIndexes]
      .sort((a, b) => b - a)
      .forEach((index) => {
        const randomAd =
          adDetails[Math.floor(Math.random() * adDetails.length)];
        postsWithAds.splice(index, 0, { type: "ad", ...randomAd });
      });

    return postsWithAds;
  };

  const adDetails = config.adDetailsForPostsPages;

  const getRandomOverlayAd = () => {
    const randomIndex = Math.floor(
      Math.random() * config.overlayAdDetails.length
    );
    return config.overlayAdDetails[randomIndex];
  };

  return (
    <div className="popular-posts">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <SearchBar
            label="人気の投稿を検索"
            placeholder="ハッシュタグを入力"
            onSubmit={handleSearchSubmit}
          />
          <div className="posts-wrapper">
            {authError ? (
              <NoResultsMessage
                message={`ログインが必要です。ログインまたは新規登録をしてください。<br />ログイン後、全てのハッシュタグ検索が無料で可能となります。`}
              />
            ) : (
              currentHashtags.map((hashtag) => (
                <div key={hashtag} className="posts-container-wrapper">
                  <h2>#「{hashtag}」で人気の投稿</h2>
                  <div className="posts-container">
                    {posts[hashtag] && posts[hashtag].length > 0 ? (
                      insertAdsRandomly(posts[hashtag], adDetails).map(
                        (item, index) =>
                          item.type === "ad" ? (
                            <AdCard
                              key={`ad-${index}`}
                              adHtml={item.adHtml}
                              isPopularPostPage={true}
                            />
                          ) : (
                            <div key={item.id} className="post-card">
                              <a
                                href={item.permalink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.media_url &&
                                item.media_url.includes(".mp4") ? (
                                  <video
                                    src={item.media_url}
                                    className="post-video post-image"
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    onError={(e) => {
                                      e.target.outerHTML = `<img src="${instagramIcon}" class="post-image" alt="Instagram Icon" />`;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.media_url ||
                                      item.thumbnail_url ||
                                      instagramIcon
                                    }
                                    alt={item.caption}
                                    className="post-image"
                                    onError={handleImageError}
                                  />
                                )}

                                <div className="post-info">
                                  <p className="post-caption">
                                    {item.caption.slice(0, 100)}...
                                  </p>
                                  <div className="post-metrics">
                                    <p className="post-likes">
                                      <FaHeart className="heart-icon" />{" "}
                                      {item.like_count}
                                    </p>
                                    <p className="post-timestamp">
                                      {new Date(
                                        item.timestamp
                                      ).toLocaleDateString()}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          )
                      )
                    ) : (
                      <NoResultsMessage />
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
      {authError && (
        <div className="auth-prompt">
          <button className="auth-button" onClick={() => navigate("/login")}>
            ログイン
          </button>
          <button className="auth-button" onClick={() => navigate("/register")}>
            新規登録
          </button>
        </div>
      )}
      {showOverlayAd && (
        <OverlayAd
          adHtml={getRandomOverlayAd().adHtml}
          onClose={() => setShowOverlayAd(false)}
        />
      )}
    </div>
  );
};

export default PopularPosts;
