import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacypolicy">
      <h1>プライバシーポリシー</h1>
      <p>
        TrendTagger（以下「当サイト」といいます）は、ユーザーの個人情報の重要性を認識し、その保護の徹底を図るため、以下の取り組みを推進いたします。
      </p>
      <h2>1. 個人情報の収集について</h2>
      <p>
        当サイトでは、ユーザーから提供された情報（名前、メールアドレス等）を適法かつ公正な手段により収集いたします。
      </p>
      <h2>2. 個人情報の利用について</h2>
      <p>収集した個人情報は、以下の目的の範囲内で利用いたします：</p>
      <ul>
        <li>サービスの提供および運営のため</li>
        <li>お問い合わせ対応のため</li>
        <li>サービス改善のための分析のため</li>
      </ul>
      <h2>3. 個人情報の第三者提供について</h2>
      <p>
        当サイトは、法令に基づく場合を除き、ユーザーの同意を得ることなく個人情報を第三者に提供することはありません。
      </p>
      <h2>4. 個人情報の管理について</h2>
      <p>
        当サイトは、個人情報の正確性を保ち、これを安全に管理するために適切な措置を講じます。
      </p>
      <h2>5. Instagram APIによるデータ取得について</h2>
      <p>
        当サイトでは、Instagram
        APIを利用して、公開されている投稿データを取得し、サイト内での分析・表示に使用しています。このデータには個人情報は含まれておらず、取得するデータは公開されているものに限定されています。取得したデータは、本プライバシーポリシーに従い、安全に管理されます。
      </p>
      <h2>6. プライバシーポリシーの変更について</h2>
      <p>
        当サイトは、必要に応じてプライバシーポリシーを変更することがあります。変更後のプライバシーポリシーについては、当サイトに掲載いたします。
      </p>
      <p>
        本ポリシーに関するお問い合わせは、下記の連絡先までお願いいたします。
      </p>
      <p>連絡先: support@trendtagger.com</p>
    </div>
  );
};

export default PrivacyPolicy;
