import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ContactForm.css";
import config from "../../config";
import { getCookie } from "../../utils/cookies"; // CSRFトークン取得のためのユーティリティを追加

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    // reCAPTCHAスクリプトを動的に読み込む
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // クリーンアップ処理: スクリプトタグの削除と reCAPTCHA バッジの削除
    return () => {
      document.body.removeChild(script);

      // reCAPTCHA バッジ（アイコン）を手動で削除
      const recaptchaBadge = document.querySelector(".grecaptcha-badge");
      if (recaptchaBadge) {
        recaptchaBadge.remove();
      }

      if (window.grecaptcha) {
        delete window.grecaptcha; // grecaptchaオブジェクトのリセット
      }
    };
  }, []); // 初回レンダリング時にのみ実行

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 1. CSRFトークンを取得
      await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });

      const csrfToken = getCookie("XSRF-TOKEN"); // CSRFトークンを取得

      window.grecaptcha.ready(async () => {
        const recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY,
          { action: "submit" }
        );

        // 2. フォームデータを送信
        const response = await axios.post(
          `${config.apiBaseUrl}/api/contact`,
          { name, email, message, recaptchaToken },
          {
            withCredentials: true, // CSRF cookie を送信
            withXSRFToken: true,
            headers: {
              "Content-Type": "application/json",
              "X-XSRF-TOKEN": csrfToken, // CSRFトークンをヘッダーに追加
            },
          }
        );

        if (response.status === 200) {
          setSubmitted(true);
        } else {
          setErrorMessage("送信に失敗しました");
        }
      });
    } catch (error) {
      setErrorMessage("エラーが発生しました。もう一度お試しください。");
    }
  };

  return (
    <div className="contact-form">
      {submitted ? (
        <p className="thank-you-message">お問い合わせありがとうございます！</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <h2>お問い合わせ</h2>
          {errorMessage && <p className="error-text">{errorMessage}</p>}
          <div className="form-group">
            <label htmlFor="name">お名前</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">メールアドレス</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">メッセージ</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit">送信</button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
