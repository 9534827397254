import React, { useState } from "react";
import axios from "axios";
import config from "../../config"; // APIベースURLの設定
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./DeleteAccount.css";

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false); // モーダル表示フラグ
  const [errorMessage, setErrorMessage] = useState(null); // エラーメッセージ
  const navigate = useNavigate();

  // CSRFトークンを取得する関数
  const csrf = () =>
    axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
      withCredentials: true,
    });

  // 退会APIリクエストを送信
  const handleDeleteAccount = async () => {
    await csrf(); // CSRFトークンを取得

    const csrfToken = Cookies.get("XSRF-TOKEN");

    try {
      // Laravel APIに退会リクエストを送信
      await axios.delete(`${config.apiBaseUrl}/api/delete-account`, {
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
        withCredentials: true, // クッキーを送信
      });

      // ローカルストレージからユーザー情報を削除
      localStorage.removeItem("user");

      // ログアウトのカスタムイベントを発行
      const event = new CustomEvent("userLogout");
      window.dispatchEvent(event);

      // ホームページにリダイレクト
      navigate("/goodbye");
    } catch (error) {
      setErrorMessage("退会処理に失敗しました。もう一度お試しください。");
    }
  };

  // モーダル表示の切り替え
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="delete-account-section">
      <h2 className="delete-heading">アカウントの退会について</h2>
      <p className="delete-description">
        アカウントを削除すると、全てのデータが失われます。本当に退会する場合は、下記のボタンをクリックしてください。
      </p>
      <button className="delete-button" onClick={toggleModal}>
        退会する
      </button>

      {/* モーダルウィンドウ */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>本当に退会しますか？</h2>
            <p>退会すると、全てのデータが削除されます。</p>
            <div className="modal-actions">
              <button onClick={handleDeleteAccount}>はい</button>
              <button onClick={toggleModal}>キャンセル</button>
            </div>
          </div>
        </div>
      )}
      {errorMessage && <p className="error-text">{errorMessage}</p>}
    </div>
  );
};

export default DeleteAccount;
