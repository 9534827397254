import React, { useState } from "react";
import axios from "axios";
import { getCookie } from "../../utils/cookies"; // CSRFトークンを取得する関数
import config from "../../config"; // APIベースURLの設定
import "./GoogleLoginButton.css";

const GoogleLoginButton = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const googleLogin = async () => {
    try {
      // 1. CSRFトークンを取得
      await axios.get(`${config.apiBaseUrl}/sanctum/csrf-cookie`, {
        withCredentials: true,
      });

      const csrfToken = getCookie("XSRF-TOKEN"); // CSRFトークンを取得

      // 2. Googleログインリクエストを送信
      const response = await axios.get(
        `${config.apiBaseUrl}/api/login/google`,
        {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            "Content-Type": "application/json",
          },
        }
      );

      const googleAuthUrl = response.data; // response.data 自体がURLであると仮定
      window.location.href = googleAuthUrl; // Google認証ページにリダイレクト

      // Google認証が成功したらリダイレクト
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
    } catch (error) {
      // エラー処理
      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Googleログインに失敗しました。再試行してください。");
      }
    }
  };

  return (
    <div className="socialLogin">
      {errorMessage && <p className="error-text">{errorMessage}</p>}
      <button className="customGoogleButton" onClick={googleLogin}>
        <img
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google Logo"
          class="google-icon"
          width="20"
        ></img>
        Googleでログイン
      </button>
    </div>
  );
};

export default GoogleLoginButton;
