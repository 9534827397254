import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setIsLoggedIn(true);
    }

    const handleUserRegistered = (event) => {
      setIsLoggedIn(true);
    };

    const handleUserLogout = () => {
      setIsLoggedIn(false);
    };

    window.addEventListener("userRegistered", handleUserRegistered);
    window.addEventListener("userLogout", handleUserLogout);

    return () => {
      window.removeEventListener("userRegistered", handleUserRegistered);
      window.removeEventListener("userLogout", handleUserLogout);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0); // ページの一番上にスクロール
  };

  return (
    <footer className="App-footer">
      <div className="footer-columns">
        <div className="footer-column left">
          <h3>各種リンク</h3>
          <nav>
            <NavLink
              to="/homepage"
              exact
              activeClassName="active"
              onClick={scrollToTop}
            >
              サイト概要
            </NavLink>
            <NavLink
              to="/"
              exact
              activeClassName="active"
              onClick={scrollToTop}
            >
              投稿ランキング
            </NavLink>
            <NavLink
              to="/analytics"
              activeClassName="active"
              onClick={scrollToTop}
            >
              投稿分析
            </NavLink>
            <NavLink
              to="/popular-hashtags"
              activeClassName="active"
              onClick={scrollToTop}
            >
              人気のハッシュタグ
            </NavLink>
            <NavLink
              to="/contact-form"
              activeClassName="active"
              onClick={scrollToTop}
            >
              お問い合わせ
            </NavLink>
            {isLoggedIn && (
              <NavLink
                to="/delete-account"
                activeClassName="active"
                onClick={scrollToTop}
              >
                退会
              </NavLink>
            )}
          </nav>
        </div>

        <div className="footer-column right">
          <h3>プライバシー</h3>
          <nav>
            <NavLink
              to="/privacy-policy"
              activeClassName="active"
              onClick={scrollToTop}
            >
              プライバシーポリシー
            </NavLink>
            <NavLink
              to="/terms-of-service"
              activeClassName="active"
              onClick={scrollToTop}
            >
              利用規約
            </NavLink>
          </nav>
        </div>
      </div>
      <p>&copy; 2024 Trend Tagger. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
