import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <header className="homepage-header">
        <h1 className="homepage-title">Welcome to TrendTagger</h1>
        <p className="homepage-subtitle">
          Your go-to source for Instagram trend insights.
        </p>
      </header>

      <section className="homepage-content">
        <p className="intro-text">
          <strong>TrendTagger</strong>は、<strong>Instagram</strong>
          の人気ハッシュタグと いいね数を元にしたランキングサイトです。
          各ハッシュタグごとの投稿ランキングや、ハッシュタグの分析情報を提供します。
        </p>

        <div className="features-section">
          <h2 className="section-title">提供機能</h2>
          <ul className="feature-list">
            <li>主要ハッシュタグの投稿ランキング</li>
            <li>
              ハッシュタグの日にち・時間ごとの"投稿数"と"いいね数"の分析ページ
            </li>
            <li>一緒につけられているハッシュタグの検索</li>
          </ul>
          <p className="login-reminder">
            ※ログイン後に各機能について、特定のハッシュタグで検索可能となります。
          </p>
        </div>

        <section className="categories-section">
          <h2 className="section-title">カバーするジャンル</h2>
          <p>
            <strong>ファッション</strong>、<strong>ビューティー</strong>、
            <strong>フィットネス</strong>、<strong>フード</strong>、
            <strong>生活用品</strong>、<strong>テクノロジー</strong>など、
            様々なジャンルの投稿をカバーしています。
          </p>
        </section>

        {/* キャッチフレーズの追加 */}
        <div className="cta-section">
          <h2 className="cta-title">ぜひ、TrendTaggerをお試しください！</h2>
          <p className="cta-text">
            今すぐInstagramのトレンドを発見して、ランキングや分析データで、最先端の情報を手に入れましょう！
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
