import React from "react";
import "./NoResultsMessage.css";

const NoResultsMessage = ({ message }) => {
  return (
    <div className="no-results-message">
      <p
        dangerouslySetInnerHTML={{
          __html: message || "検索結果が見当たりません。",
        }}
      />
    </div>
  );
};

export default NoResultsMessage;
